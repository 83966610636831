<template>
    <section class="recovery container flex-center">
        <div class="row w-100 flex-center">

            <div class="col-12 col-sm-9 col-md-7 col-lg-6 text-center px-3 py-4 p-sm-5" 
                style="background-color: #FFFFFFFD; border-radius: 8px;"
                v-if="step == 1">

                <span class="ge--description-sm">
                    <b>ADMINISTRADOR</b>
                </span>
                    
                <img :src="img_logo" class="img-fluid mb-2">

                <ge-input v-model="email" 
                    placeholder="Correo" 
                    class="mb-5"/>

                <button @click="sendCode()" 
                    class="btn btn-primary btn-pill w-100 mb-2">
                        <span class="ge--description-sm text-white">
                            Enviar código
                        </span>
                </button>
            </div>

            <div class="col-12 col-sm-9 col-md-7 col-lg-6 text-center px-3 py-4 p-sm-5" 
                style="background-color: #FFFFFFFD; border-radius: 8px;"
                v-if="step == 2">

                <span class="ge--description-sm">
                    <b>ADMINISTRADOR</b>
                </span>
                    
                <img :src="img_logo" class="img-fluid mb-2">

                <div class="col-12 d-flex justify-content-center mb-4">
                    <input id="input_token_0" 
                        v-model="tokens[0]" 
                        @input="inputToken(0)"
                        class="ge--description-sm w-100 text-center rounded m-1 m-sm-2 p-2 p-sm-3"/>

                    <input id="input_token_1" 
                        v-model="tokens[1]" 
                        @input="inputToken(1)"
                        class="ge--description-sm w-100 text-center rounded m-1 m-sm-2 p-2 p-sm-3"/>

                    <input id="input_token_2" 
                        v-model="tokens[2]" 
                        @input="inputToken(2)"
                        class="ge--description-sm w-100 text-center rounded m-1 m-sm-2 p-2 p-sm-3"/>

                    <input id="input_token_3" 
                        v-model="tokens[3]" 
                        @input="inputToken(3)"
                        class="ge--description-sm w-100 text-center rounded m-1 m-sm-2 p-2 p-sm-3"/>

                    <input id="input_token_4" 
                        v-model="tokens[4]" 
                        @input="inputToken(4)"
                        class="ge--description-sm w-100 text-center rounded m-1 m-sm-2 p-2 p-sm-3"/>

                    <input id="input_token_5" 
                        v-model="tokens[5]" 
                        @input="inputToken(5)"
                        class="ge--description-sm w-100 text-center rounded m-1 m-sm-2 p-2 p-sm-3"/>
                </div>

                <button id="btn_verify"
                    @click="validateCode()" 
                    class="btn btn-primary btn-pill w-100 mb-2">
                        <span class="ge--description-sm text-white">
                            Verificar código
                        </span>
                </button>
            </div>

            <div class="col-12 col-sm-9 col-md-7 col-lg-6 text-center px-3 py-4 p-sm-5" 
                style="background-color: #FFFFFFFD; border-radius: 8px;"
                v-if="step == 3">

                <span class="ge--description-sm">
                    <b>ADMINISTRADOR</b>
                </span>
                    
                <img :src="img_logo" class="img-fluid mb-2">

                <div class="col-12 d-flex justify-content-center mb-2">
                    <ge-input v-model="password" 
                        placeholder="Nueva contraseña" 
                        type="password" 
                        class="mb-4"/>
                </div>

                <div class="col-12 d-flex justify-content-center mb-4">
                    <ge-input v-model="confirm_password" 
                        placeholder="Confirmar contraseña" 
                        type="password" 
                        class="mb-4"/>
                </div>

                <button id="btn_verify"
                    @click="createNewPassword()" 
                    class="btn btn-primary btn-pill w-100 mb-2">
                        <span class="ge--description-sm text-white">
                            Cambiar contraseña
                        </span>
                </button>
            </div>

        </div>
        
    </section>
</template>

<script setup>
    import { ref, inject } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"
    // Images
    const img_logo = ref(require('ASSETS/images/logo'))

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects
    const $sendRequest = inject('$sendRequest')
    const $redirect = inject('$redirect')

    // Computeds

    // Variables
    const step = ref(1)
    const email = ref(null)
    const tokens = ref([ null, null, null, null, null, null ])
    const password = ref(null)
    const confirm_password = ref(null)

    // Methods
    const onlyNumber = (value) => {
        return value.replace(/[^\d]/, '')
    }
    const inputToken = (i_token) => {
        tokens.value[i_token] = onlyNumber(tokens.value[i_token])

        if(tokens.value[i_token] !== null && tokens.value[i_token].length > 1) {
            tokens.value[i_token] = tokens.value[i_token][1]
        }

        if(i_token < 5) {
            // console.log(`input_token_${Number(i_token) + 1}`)
            document.getElementById(`input_token_${Number(i_token) + 1}`).focus()
        }
        else {
            document.getElementById('btn_verify').focus()
        }
    }

    const sendCode = async() => {
        store.dispatch('showLoading')

        let body = {
            email: email.value
        }

        let response = await $sendRequest('administrator/send_code', 'POST', body)

        if(response.success && response.data) {
            step.value = 2
            store.dispatch('showAlert', 'El código ha sido enviado a su correo')
            store.dispatch('hiddenLoading')
        }
        else if(!response.success && response.errors) {
            let message = []
            for(let error of response.errors) {
                if(error.code == 'error_empty_email') {
                    message.push('El campo "Correo" es requerido')
                }
                else if(error.code == 'error_user_not_found') {
                    message.push('El correo no ha sido encontrado')
                }
                else {
                    message.push('Ha ocurrido un error')
                }
            }
            store.dispatch('showError', message)
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    const validateCode = async() => {
        store.dispatch('showLoading')

        let code = ''
        for(let i in tokens.value) {
            code += tokens.value[i]
        }

        let query = {
            email: email.value,
            code: code
        }

        let response = await $sendRequest('administrator/validate_code', 'GET', null, query)

        if(response.success && response.data) {
            step.value = 3
            store.dispatch('hiddenLoading')
        }
        else if(!response.success && response.errors) {
            let message = []
            for(let error of response.errors) {
                if(error.code == 'error_code_not_found') {
                    message.push('El código es incorrecto')
                }
                else {
                    message.push('Ha ocurrido un error')
                }
            }
            store.dispatch('showError', message)
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    const createNewPassword = async() => {
        store.dispatch('showLoading')

        if(password.value !== confirm_password.value) {
            store.dispatch('showError', 'Las contraseñas deben coincidir')
            store.dispatch('hiddenLoading')
            return
        }

        let code = ''
        for(let i in tokens.value) {
            code += tokens.value[i]
        }

        let body = {
            email: email.value,
            code: code,
            new_password: password.value
        }

        let response = await $sendRequest('administrator/change_password', 'PUT', body)

        if(response.success && response.data) {
            store.dispatch('showAlert', 'La contraseña ha sido cambiada con éxito')
            store.dispatch('hiddenLoading')
            $redirect('login-admin')
        }
        else if(!response.success && response.errors) {
            store.dispatch('showError', 'Ha ocurrido un error')
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

</script>

<style>
    .recovery {
        min-height: calc(100vh - 48px);
    }
</style>